import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/analytics";
import "firebase/functions";

const config = {
  apiKey: "AIzaSyCuJHJpBCjBzTt7zaShMM9w6XRNbMAl6GA",
  authDomain: "mahjong-pals.firebaseapp.com",
  databaseURL: "https://mahjong-pals.firebaseio.com",
  projectId: "mahjong-pals",
  storageBucket: "mahjong-pals.appspot.com",
  messagingSenderId: "447355269813",
  appId: "1:447355269813:web:ff448583b7b848967744fc",
  measurementId: "G-QJ3PL569GZ"
};

firebase.initializeApp(config);
firebase.analytics();

export const authProvider = new firebase.auth.GoogleAuthProvider();

export const createGame = firebase.functions().httpsCallable("createGame");

export default firebase;
