import React from "react";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

import InternalLink from "../components/InternalLink";

function AboutPage() {
  return (
    <Container>
      <Typography variant="h4" align="center" style={{ marginTop: 24 }}>
        About
      </Typography>
      <Paper style={{ padding: "1rem", maxWidth: 720, margin: "12px auto" }}>
        <Typography variant="body1" gutterBottom>
          This is a future mahjong app, based on setwithfriends.com by
          <Link href="https://github.com/ekzhang">Eric Zhang</Link> and{" "}
          <Link href="https://github.com/cynthiakedu">Cynthia Du</Link>, from their 
          source code at{" "}
          <Link href="https://github.com/ekzhang/setwithfriends">
            ekzhang/setwithfriends
          </Link>
          . Go give them a star!
        </Typography>
      </Paper>
      <Typography variant="body1" align="center" gutterBottom>
        <InternalLink to="/">Return to home</InternalLink>
      </Typography>
    </Container>
  );
}

export default AboutPage;
